import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { Modal, Button } from 'react-bootstrap';
import Compressor from 'compressorjs';

import { canvasPreview } from './CanvasPreview';
import { IMAGE_SIZE_COMPRESS_LIMIT } from '../../../config';

const data = {
  buck: { title: 'NFT buck' },
  bundle: { aspect: 0.6, dimension: ' ' },
  starConnect: {
    aspect: 0.875,
    title: 'StarConnect NFT',
    dimension: '(700px x 800px)'
  },
  snft_main: {
    aspect: 1,
    title: 'Brand Club',
    dimension: '(1000px x 1000px)'
  },
  snft_marketplace: {
    aspect: 1.23,
    title: 'Marketplace Thumbnail',
    dimension: '(860px x 700px)'
  },
  snft_accesslevel: {
    aspect: 1.74,
    title: 'Access Level',
    dimension: '(495px x 354px)'
  },
  snft_banner1: { aspect: 1.8, title: 'Banner', dimension: '(1440px x 798px)' },
  snft_banner2: {
    aspect: 1.77,
    title: 'Thumbnail',
    dimension: '(1280px x 720px)'
  },
  snft_banner3: {
    aspect: 1.36,
    title: 'Thumbnail',
    dimension: '(598px x 440px)'
  },
  snft_cover: { aspect: 3.84, title: 'Cover', dimension: '1920px x 500px' },
  snft_welcome_msg: {
    aspect: 1.77,
    title: 'Header Cover',
    dimension: '(1600 x 900px)'
  },
  promotion: { aspect: 1.01, dimension: '(370px x 375px)' },
  accessNft_collection: { title: 'AccessNFT collection' },
  accessNft: {
    aspect: 0.75,
    title: 'AccessNFT',
    dimension: '(1200px x 1600px)'
  },
  profilePicture: {
    aspect: 1,
    title: 'Profile Picture',
    dimension: ' '
  },
  starprofile_header: {
    aspect: 0.973,
    dimension: '*Image size 1600x1800px or 8:9'
  }
};

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop({ unit: '%', width: 100 }, aspect, mediaWidth, mediaHeight),
    mediaWidth,
    mediaHeight
  );
}

const CropModal = (props) => {
  const {
    src = {},
    showCropModal = false,
    closeModal = () => {},
    handleSave = () => {},
    type = 'buck'
  } = props;
  const [originalImageFile, setOriginalImageFile] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState({});
  const [width, setWidth] = useState('30vh');
  const aspect = data[type]?.aspect || 0.5;
  const canvas = useRef();
  const imageRef = useRef();

  const handleSaveImage = () => {
    if (completedCrop.width && completedCrop.height) {
      canvas.current.toBlob(function (blob) {
        const fileName = originalImageFile?.name || originalImageFile?.path;
        new Compressor(blob, {
          quality: type.includes('_banner')
            ? 0.8
            : blob.size > IMAGE_SIZE_COMPRESS_LIMIT
            ? 0.2
            : 0.4,
          success: (result) => {
            const file = new File([result], fileName, { type: result.type });
            file.path = originalImageFile.path;
            return handleSave(file);
          }
        });
      });
    } else return closeModal();
  };

  useEffect(() => {
    if (showCropModal) {
      if (src) setOriginalImageFile(src);
      else {
        setOriginalImageFile(null);
        setCrop(null);
        setCompletedCrop({});
      }
    }
  }, [src, showCropModal]);

  useEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height &&
      imageRef.current &&
      canvas.current
    ) {
      canvasPreview(imageRef.current, canvas.current, completedCrop, 1, 0);
    }
  }, [completedCrop]);

  return (
    <>
      <Modal
        centered
        className="crop-modal"
        show={showCropModal}
        onHide={() => closeModal()}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Crop Image to Fit</h4>
            <p className="text-white mb-0 mt-2 opacity-75">
              Please crop your image to fit{' '}
              <span className="text-capitalize">
                {data[type]?.title || type}
              </span>{' '}
              image proportions. {data[type]?.dimension || '(600px x 1200px)'}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column">
          <ReactCrop
            circularCrop={type === 'profilePicture'}
            aspect={aspect}
            crop={crop ? crop : { unit: '%', x: 25, y: 25 }}
            onComplete={(c) => setCompletedCrop(c)}
            onChange={(c) => setCrop(c)}
            style={{ maxHeight: '70vh', width: width }}
            ruleOfThirds
          >
            <div style={{ height: '100%', width: '100%' }}>
              <img
                ref={imageRef}
                src={originalImageFile?.preview || ''}
                onLoad={(e) => {
                  const { width, height } = e.currentTarget;
                  if (width > height) setWidth('100%');
                  else setWidth('30vh');
                  setCrop(centerAspectCrop(width, height, aspect));
                }}
                height="100%"
                width="100%"
                alt="cropper"
              />
            </div>
          </ReactCrop>
          <Button
            disabled={completedCrop.height ? false : true}
            onClick={handleSaveImage}
            className="mt-4 w-100"
            variant="outline-primary"
            size="md"
          >
            Crop Image
          </Button>
        </Modal.Body>
      </Modal>
      <canvas
        ref={canvas}
        style={{
          display: 'none',
          objectFit: 'contain',
          width: completedCrop.width,
          height: completedCrop.height
        }}
      />
    </>
  );
};

export default CropModal;
