import axios from 'axios';
import { END_POINT_URL, MINT_API_URL } from './config';
import utils from './utils';

const END_POINT_URL_V1 = END_POINT_URL + '/v1/';
const END_POINT_URL_V2 = END_POINT_URL + '/v3/';

const cancelToken = axios.CancelToken.source();
export const instance = axios.create({
  baseURL: END_POINT_URL_V2,
  headers: { 'Content-Type': 'application/json' }
});

const apiCall = async ({
  method = 'GET',
  url = '',
  params = {},
  data = {},
  timeout = 60000
}) => {
  try {
    const response = await instance({ method, url, params, data, timeout });
    return response.data;
  } catch (error) {
    console.log(error);
    return error?.response?.data || { status: error?.status || 500 };
  }
};

const formDataApiCall = async ({
  method = 'POST',
  url = '',
  params = {},
  formData: data = {},
  timeout = 1200000
}) => {
  try {
    // const formData = new FormData();
    // for (const key in data) {
    //   formData.append(key, data[key]);
    // }
    const headers = { 'Content-Type': 'multipart/form-data' };
    const options = { headers, timeout };
    const response = await instance({ method, url, params, data, ...options });
    // if (respone.status !== 200) errorMsg('Something went wrong');
    // else successMsg(respone.data.message);
    return response.data;
  } catch (error) {
    return error?.response?.data || {};
  }
};

instance.interceptors.request.use((config) => {
  const encryptedData = localStorage.getItem('session_data');
  if (encryptedData) {
    const { accessKey = '' } = utils.getDecrypted(encryptedData);
    config.headers.Authorization = 'Bearer ' + accessKey;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response.status !== 200) return Promise.reject(response || {});
    return Promise.resolve(response);
  },
  async (error) => {
    const status = error?.response?.status || 0;
    if ([401, 403].includes(status) && !error.config._retry) {
      try {
        let data = utils.getDecrypted(localStorage.getItem('session_data'));
        delete data.timestamp;
        localStorage.setItem('session_data', utils.getEncrypted(data));
        setTimeout(() => {
          if (cancelToken) cancelToken.cancel('Session Expired!');
          window.location.reload();
        }, 500);
        return Promise.resolve({ message: 'Session Expired!' });
      } catch (err) {
        console.log(err);
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export const APIUtiltity = {
  // Master data
  getMasterData: () => apiCall({ url: END_POINT_URL_V1 + 'master-data' }),

  // Connect Flow
  generateEmailOtp: (email = '', type = 'LOGIN_OTP') =>
    apiCall({ method: 'POST', url: 'user/getCode', data: { email, type } }),

  crossLogin: (token = '') =>
    apiCall({ method: 'POST', url: 'user/login', data: { token } }),

  verifyEmailOtp: (email = '', code = '', type = 'LOGIN_OTP') =>
    apiCall({
      method: 'POST',
      url: 'user/verifyCode',
      data: { email, code, type }
    }),

  setupNewUser: (data = {}) =>
    apiCall({ method: 'POST', url: 'user/setup', data: data }),

  getUserData: () => apiCall({ url: 'user/userStatus', params: {} }),

  // wallet settings
  getPrivateKey: (txnPwd = '', code = '', type = 'TPASSWORD') =>
    apiCall({
      method: 'POST',
      url: 'user/privateKey',
      data: { txnPwd, code, type }
    }),

  resetTxnPassword: (payload = '') =>
    apiCall({ method: 'POST', url: 'user/forgetPassword', data: { payload } }),

  // search BranchClub / Star
  searchBrandOrStar: (search = '') =>
    apiCall({ url: 'marketPlace/brand/' + search }),

  // User logins
  getUserStatus: (account) => apiCall({ url: 'user/userStatus/' + account }),

  getNicknamebyWalletId: (walletId = '') =>
    apiCall({ url: `user/getNickname/${walletId}` }),

  hasCreatorPageAccess: (account) =>
    apiCall({ url: 'user/creatorHub/access/' + account }),

  getAllNotifications: (walletId, page) =>
    apiCall({
      url: 'user/getAllNotification',
      params: { walletId, page, limit: 20 }
    }),

  markAsReadAllNotifications: (data = {}) =>
    apiCall({ method: 'PUT', url: 'notification/markAsRead', data }),

  getProfileDetails: (account) => apiCall({ url: 'user/profile/' + account }),

  getUserBalances: (account) =>
    apiCall({ url: 'user/balance/crypto/' + account }),

  getUserBucksBalance: (account) =>
    apiCall({ url: 'user/balance/bucks/' + account }),

  getCartByWalletId: (walletId) =>
    apiCall({ url: 'carts/getCart', params: { walletId } }),

  // User Balance
  getUserBalance: (account) => apiCall({ url: 'user/balance/' + account }),

  // sNFT Marketplace
  getMarketPlaceData: (params = {}) =>
    apiCall({ url: 'marketplace/getFilteredSnft', params: params }),

  // Bucks Stakehub
  getUserBucksWithDenomination: (collectionType = '') => {
    let params = { limit: 100 };
    if (collectionType) params.collectionType = collectionType;
    return apiCall({ url: 'order/getUserBucks', params: params });
  },

  // getAllActiveBucksPerCollection: (walletId, collectionId) =>
  //   apiCall({
  //     url: `collection/getAllActiveBucksPerCollection/${walletId}/${collectionId}`,
  //     params: { page: 1, limit: 100 }
  //   }),

  getMyAssets: () => apiCall({ url: 'user/myAssets' }),

  // Common upload File
  uploadFile: (file, walletId = '', category = '') => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('category', category);
    return formDataApiCall({
      url: 'user/uploadFile',
      formData: formData,
      params: { walletId }
    });
  },

  //StarProfile CreatorHub
  getStarProfileDetails: (account) =>
    apiCall({ url: 'user/profile/star/' + account }),

  updateProfilePic: (account, profilePicObj) =>
    apiCall({
      method: 'PUT',
      url: 'user/profilePic/' + account,
      data: profilePicObj
    }),

  editStarProfile: (account, payload) =>
    apiCall({
      method: 'POST',
      url: 'user/star/profile/' + account,
      data: payload
    }),

  editStarProfileSocial: (account, body) =>
    apiCall({
      method: 'POST',
      url: 'user/star/profile/social/' + account,
      data: body
    }),

  // sNFT CreatorHub

  getFanList: (type = 'recent') =>
    apiCall({ url: 'fan/getFanByActivity/' + type }),

  getSnftsMintingInProgress: (params = {}) =>
    apiCall({ url: 'snft/inProgress', params: params }),

  getCreatedSnftsByWalletId: (params = {}, data = {}) =>
    apiCall({ method: 'POST', url: 'snft/creator', params, data }),

  getCreatedSnftMembersBySnftId: (snftId = '', params = {}) =>
    apiCall({ url: 'snft/creator/' + snftId, params: params }),

  getSnftSaleAndStats: (params = {}) =>
    apiCall({ url: 'creatorhub/snftSaleAndStats', params: params }),

  getCreatedSnftDataById: (snftId = '', params = {}) =>
    apiCall({ url: `snft/creator/${snftId}/details`, params: params }),

  updateSnftActiveStatus: (snftId = '') =>
    apiCall({ method: 'PUT', url: `snft/${snftId}/status`, data: { snftId } }),

  createSnftPost: (data = {}) => apiCall({ method: 'POST', url: 'post', data }),

  updateSnftPost: (data = {}) => apiCall({ method: 'PUT', url: 'post', data }),

  deleteSnftPost: (postId = '') =>
    apiCall({ method: 'DELETE', url: 'post', data: { postId } }),

  getAllSnftPosts: (snftId = '', params = {}) =>
    apiCall({ url: 'post/getAll/' + snftId, params: params }),

  togglePostReactions: (postId = '', data = {}) =>
    apiCall({ method: 'PUT', url: `post/togglePostReactions/${postId}`, data }),

  getSnftPostComments: (postId = '', params = {}) =>
    apiCall({ url: '/socialComment/getAll/' + postId, params: params }),

  createSnftPostComment: (data = {}) =>
    apiCall({ method: 'POST', url: '/socialComment', data: data }),

  updateSnftPostComment: (data = {}) =>
    apiCall({ method: 'PUT', url: '/socialComment', data: data }),

  deleteSnftPostComment: (commentId = '') =>
    apiCall({ method: 'DELETE', url: '/socialComment', data: { commentId } }),

  toggleCommentLike: (commentId = '') =>
    apiCall({
      method: 'PUT',
      url: `socialComment/toggleCommentlike/${commentId}`,
      data: { commentId: commentId }
    }),

  getSnftPostReplies: (parentCommentId = '', params = {}) =>
    apiCall({ url: 'socialComment/getAllReplies/' + parentCommentId, params }),

  updateWelcomeMessage: (snftId = '', data = {}) =>
    apiCall({
      method: 'PUT',
      url: `snft/updateWelcomeMessage/${snftId}`,
      data: data
    }),

  setSnftSettings: (walletId, snftId, data = {}) =>
    apiCall({
      method: 'POST',
      url: `snft/setting/${walletId}/${snftId}`,
      data: data
    }),

  // sNFT Creation
  createSnft: (data = {}) =>
    apiCall({ method: 'POST', url: 'snft/create', data: data }),

  updateSnft: (data = {}) =>
    apiCall({ method: 'PUT', url: 'snft/update', data: data }),

  getSnftDataById: (snftId = '') => apiCall({ url: 'snft/' + snftId }),

  // sNFT access level CRUD
  createSnftAccessLevel: (data = {}) =>
    apiCall({ method: 'POST', url: 'snft/accessLevel/create', data: data }),

  updateSnftAccessLevel: (data = {}) =>
    apiCall({ method: 'PUT', url: 'snft/accessLevel/update', data: data }),

  deleteSnftAccessLevel: (accessLevelId = '') =>
    apiCall({ method: 'DELETE', url: 'snft/accessLevel/' + accessLevelId }),

  getSelectableAccessNFTs: (params = {}, data = {}) =>
    apiCall({ method: 'POST', url: 'accessNft/getAll', params, data }),

  // sNFT page builder setup
  setupSnftPageBuilder: (data = {}) =>
    apiCall({ method: 'POST', url: 'snft/pageBuilder/create', data: data }),

  updateSnftPageBuilder: (data = {}) =>
    apiCall({ method: 'PUT', url: 'snft/pageBuilder/update', data: data }),

  getSnftPageBuilderDataById: (snftPageBuilderId = '', params = {}) =>
    apiCall({ url: 'snft/pageBuilder/' + snftPageBuilderId, params: params }),

  // sNFT final review & submit
  snftFinalSubmitForReview: (snftId = '') =>
    apiCall({
      method: 'POST',
      url: 'snft/submit',
      data: { snftId: snftId },
      timeout: 1200000
    }),

  // sNFT promotions
  createPromotion: (data = {}) =>
    apiCall({ method: 'POST', url: 'promotion/create', data: data }),

  getAllSnftForPromotion: (params = {}) =>
    apiCall({ url: 'snft/creator/promotion', params }),

  getAllPromotions: (walletId = '', type = '', params = {}) =>
    apiCall({ url: 'promotion/get/' + walletId + '/' + type, params }),

  getPromotionDetailsById: (promotionId = '') =>
    apiCall({ url: 'promotion/id/' + promotionId }),

  getPromotionWinner: (promotionId = '') =>
    apiCall({ url: 'promotion/isWinner/' + promotionId }),

  getApprovedAdbuckForPromotion: (walletId = '', params = {}) =>
    apiCall({ url: 'bucks/getApprovedAdbuckForPromotion/' + walletId, params }),

  // sNFT details page
  getSnftDetailsById: (snftId = '', params = {}) =>
    apiCall({ url: `snft/${snftId}/details`, params: params }),

  getPromotionsBySnftId: (snftId = '', params = {}) =>
    apiCall({ url: 'promotion/markethub/' + snftId, params: params }),

  purchaseSnft: (requestBody) =>
    apiCall({
      method: 'POST',
      url: `marketPlace/purchaseSnft`,
      data: requestBody,
      timeout: 1200000
    }),

  // Staked sNFT
  getAllMyStakedInft: (params = {}, data = {}) =>
    apiCall({
      method: 'POST',
      url: 'marketPlace/myStakedSnft',
      params: params,
      data: data
    }),

  setReferralWalletAddress: (referredWalletId = '') =>
    apiCall({ method: 'POST', url: '/referral', data: { referredWalletId } }),

  getAllPurchaseSnft: () => apiCall({ url: 'snft/purchased/all' }),

  getMyStakedSnftDetails: (snftId = '') =>
    apiCall({ url: `marketPlace/myStakedSnft/${snftId}/details` }),

  getStakedSnftRewards: (snftId) => apiCall({ url: `snft/rewards/${snftId}` }),

  getStakehubSnftEarning: () => apiCall({ url: 'snft/stakeHub/earning' }),

  getStakehubReferralEarning: (params = {}) =>
    apiCall({ url: 'referral/earning', params: params }),

  getSnftLeaderboardData: (snftId = '', type = '') =>
    apiCall({ url: `snft/leaderboard/${snftId}/${type}` }),

  getPromotionLeaderboardData: (snftId = '', promotionId = '', params = {}) =>
    apiCall({ url: `promotion/leaderboard/${snftId}/${promotionId}`, params }),

  getSharedSalesPromotionRewardData: (promotionId = '') =>
    apiCall({ url: 'promotion/reward/' + promotionId }),

  getFanScoreByStarWalletId: (starWalletId = '') =>
    apiCall({ url: 'fan/score/' + starWalletId }),

  // Staked sNFT promotions
  getAllStakedPromotions: (params = {}) =>
    apiCall({ url: 'snft/stakehub/promotion', params }),

  // Watchlist sNFT
  getSnftWatchList: () => apiCall({ url: 'watchList/', params: {} }),

  addSnftToWatchList: (snftId = '') =>
    apiCall({ method: 'POST', url: 'watchList/' + snftId, data: {} }),

  removeSnftFromWatchList: (snftId = '') =>
    apiCall({ method: 'DELETE', url: `watchList/${snftId}`, data: {} }),

  getSnftWatchListDetails: (params = {}) =>
    apiCall({ url: 'watchList/details', params: params }),

  // creatorhub
  getMyCreatedAssets: () => apiCall({ url: 'user/creator/myAssets' }),

  getCreatorOverviewData: () => apiCall({ url: 'creatorHub/earning' }),

  getCreatorSnftLeaderboardData: (params = {}) =>
    apiCall({ url: 'snft/creator/leaderBoard', params: params }),

  getCreatorBuckLeaderboardData: (params = {}) =>
    apiCall({ url: 'bucks/creator/leaderBoard', params: params }),

  getCreatorAccessNftLeaderboardData: (params = {}) =>
    apiCall({ url: 'creatorHub/accessNft/leaderBoard', params: params }),

  getFanConnectedStatus: (starWalletId = '') =>
    apiCall({ url: 'starConnect/getStarConnectClaimStatus/' + starWalletId }),

  getFanStatusData: () => apiCall({ url: 'fan/count' }),

  getFanChecklistData: (starWalletId = '') =>
    apiCall({ url: 'fan/status/checklist/' + starWalletId }),

  getTopSuperFansData: () => apiCall({ url: 'fan/super' }),

  getAllFanStatusSettings: (walletId = '') =>
    apiCall({ url: 'fanStatus/all/' + walletId, params: {} }),

  getFanSettingsByFanStatus: (walletId = '', fanStatus = '') =>
    apiCall({ url: `fanStatus/${walletId}/${fanStatus}`, params: {} }),

  updateFanStatusSettings: (data = {}) =>
    apiCall({ method: 'POST', url: 'fanStatus', data: data }),

  getCreatorFanSettings: (walletId = '') =>
    apiCall({ url: 'fan/status/checklist-custom/' + walletId }),

  // NFTBucks CreatorHub
  getBuckSaleAndStats: (params = {}) =>
    apiCall({ url: 'creatorhub/getBuckSaleAndStats', params }),

  getCreatorAdBuckDropHistory: (params = {}) =>
    apiCall({ url: 'creatorHub/adBuck/drop', params: params }),

  getBucksByWalletId: (params = {}) =>
    apiCall({ url: 'bucks/getAllBuckByWalletId', params }),

  getCollectionByWalletIdnType: (walletId = '', type = '', params = {}) =>
    apiCall({ url: `collection/getAll/${walletId}/${type}`, params }),

  getBundleByWalletId: (walletId = '', params = {}) => {
    let url = '';
    if (walletId) url = `bundle/getAll/${walletId}`;
    else url = `bundle/getAll`;
    return apiCall({ url: url, params });
  },

  getcreatorHubDashborad: (walletId) =>
    apiCall({ url: `dashboard/CreatorHub/bucks/${walletId}` }),

  checkDuplicateTitle: (type, title) =>
    apiCall({
      method: 'POST',
      url: `${type}/checkUniqueTitle`,
      data: { title }
    }),

  // NFTBucks - Buck Creation
  getCollection: (walletId) =>
    apiCall({ url: `collection/getAllCollectionName/${walletId}` }),

  getRarityInfo: (walletId = '', collectionId = '') =>
    apiCall({ url: `collection/getRarityInfo/${walletId}/${collectionId}` }),

  createBucks: (account = '', data = {}) =>
    apiCall({
      method: 'POST',
      url: 'bucks/create/' + account,
      data: data,
      timeout: 1200000
    }),

  createAdbucks: (account = '', data = {}) =>
    apiCall({
      method: 'POST',
      url: 'bucks/adbuck/' + account,
      data: data,
      timeout: 1200000
    }),

  // NFTBucks - Collection Creation
  createCollection: (account, formData) =>
    formDataApiCall({ url: 'collection/create/' + account, formData }),

  // NFTBucks - Bundle Creation
  createBundle: (account, formData) =>
    formDataApiCall({ url: 'bundle/create/' + account, formData: formData }),

  getApprovedBucksByWalletId: (creatorWalletId = '') =>
    apiCall({ url: `bucks/${creatorWalletId}` }),

  // Access NFTs
  getAccessTokensByWalletId: (page, limit, body) =>
    apiCall({
      method: 'POST',
      url: `accessNft/creator`,
      data: body,
      params: { page, limit }
    }),

  createAccessNFT: (requestBody = {}) =>
    apiCall({ method: 'POST', url: `accessNft/`, data: requestBody }),

  updateAccessNFT: (requestBody = {}) =>
    apiCall({
      method: 'PUT',
      url: `accessNft/`,
      data: requestBody,
      timeout: 1200000
    }),

  getAccessTokenById: (accessTokenId) =>
    apiCall({ url: 'inft/getAccessToken', params: { accessTokenId } }),

  getAccessTokenDetail: (accessTokenId) =>
    apiCall({
      url: `inft/getAccessTokenPageAccess`,
      params: { accessTokenId }
    }),

  getCreatedAccessNFTs: (params = {}, data = {}) =>
    apiCall({ method: 'POST', url: 'accessNft/creator', params, data }),

  getCreatedAccessNFTsCount: (params = {}) =>
    apiCall({ url: 'accessNft/creator/myAssets', params }),

  getOpenOrderAccessNFTsByCreator: (params = {}, data = {}) =>
    apiCall({
      method: 'POST',
      url: 'accessNft/openOrder/creator',
      params: params,
      data: data
    }),

  getAccessNftDeliveryNotifications: (type = '', params = {}) =>
    apiCall({ url: 'accessnft/notification/' + type, params: params }),

  getPopularAccessNft: (walletId) =>
    apiCall({ url: `inft/getPopularAccessNft`, params: { walletId } }),

  getAccesNftData: (accessTokenId) =>
    apiCall({
      url: `/marketPlace/${accessTokenId}/connectedInft`,
      params: { accessTokenId }
    }),

  getAccessNftSaleAndStats: (params = {}) =>
    apiCall({ url: 'creatorHub/accessNftSaleAndStats', params }),

  // Access NFT collection
  createAccessNFTCollection: (formData) =>
    formDataApiCall({ url: `accessNftCollection`, formData }),

  getCreatedAccessNFTCollections: (params = {}) =>
    apiCall({ url: 'accessNftCollection/creator', params: params }),

  getAccessNFTCollectionById: (accessNftCollectionId = '', params = {}) =>
    apiCall({ url: 'accessNftCollection/' + accessNftCollectionId, params }),

  // Access NFT Details page
  getCreatedAccessNFTById: (accessNftId = '', params = {}) =>
    apiCall({ url: 'accessNft/' + accessNftId, params: params }),

  getAccessNFTTableDataById: (accessNftId = '', params = {}) =>
    apiCall({ url: 'accessnft/leaderboard/' + accessNftId, params }),

  getAccessNFTById: (accessNftId = '', params = {}) =>
    apiCall({ url: 'accessNft/markethub/' + accessNftId, params: params }),

  getRatingByAccessNftId: (accessNftId = '', params = {}) =>
    apiCall({ url: 'accessnft/rating/' + accessNftId, params: params }),

  getReviewsByAccessNftId: (accessNftId = '', params = {}) =>
    apiCall({ url: 'accessnft/review/' + accessNftId, params: params }),

  updateAccessNFTActiveStatus: (data = {}) =>
    apiCall({ method: 'PUT', url: 'accessNft/creator/status', data }),

  updateAccessNftSettings: (walletId = '', accessNftId = '', data = {}) =>
    apiCall({
      method: 'POST',
      url: 'accessnft/setting/' + walletId + '/' + accessNftId,
      data
    }),

  updateAccessNFTCollectionSettings: (
    walletId = '',
    accessNftCollectionId = '',
    data = {}
  ) =>
    apiCall({
      method: 'POST',
      url:
        `accessNftCollection/setting/` + walletId + '/' + accessNftCollectionId,
      data
    }),

  // AccessNFT purchase
  purchaseAccessNFT: (data = {}) =>
    apiCall({
      method: 'POST',
      url: 'accessNft/purchase',
      data: data,
      timeout: 1200000
    }),

  // Brand Club Access NFTs
  getAccessNftByBrandClubId: (snftId = '', params = {}, data = {}) =>
    apiCall({
      method: 'POST',
      url: 'accessNft/getAccessNftByBrandClub/' + snftId,
      params: params,
      data: data
    }),

  // Purcahsed AccessNFTs
  getPurchasedAccessNFTsCount: () => apiCall({ url: 'accessNft/staked' }),

  claimCertificateAccessNFT: (code = '') =>
    apiCall({ method: 'POST', url: 'accessNft/claim', data: { code } }),

  getPurchasedOpenOrderAccessNFTs: (walletId = '', params = {}, data = {}) =>
    apiCall({
      method: 'POST',
      url: `accessNft/openOrder/${walletId}`,
      params: params,
      data: data
    }),

  getOpenOrderAccessNftDetail: (deliveryDetailId = '') =>
    apiCall({ url: 'accessNft/orderDetail/' + deliveryDetailId }),

  updateOpenOrderAccessNftDetail: (endPoint = '', data = {}) =>
    apiCall({ method: 'PUT', url: 'accessNft/updateOrder/' + endPoint, data }),

  // Buck Details page
  getBuckById: (buckId) =>
    apiCall({ url: 'bucks/getBuckById', params: { buckId } }),

  getAdBuckById: (buckId) =>
    apiCall({ url: 'bucks/getAdBuckById', params: { buckId } }),

  checkIsBuckInCart: (buckId, walletId) =>
    apiCall({ url: `bucks/checkIsBuckInCart`, params: { buckId, walletId } }),

  getAllBundlePerBucks: (account, buckId) =>
    apiCall({ url: `bucks/getAllBundlePerBucks/${account}/${buckId}` }),

  setBuckSettings: (walletId, buckId, data = {}) =>
    apiCall({
      method: 'POST',
      url: `bucks/setting/${walletId}/${buckId}`,
      data
    }),
  updateBuckStatus: (walletId, buckStatusObj) =>
    apiCall({
      method: 'PUT',
      url: `bucks/updateBuckStatus/${walletId}`,
      data: buckStatusObj
    }),

  getBucksByRarityClass: (params) =>
    apiCall({ url: `bucks/getBucksByRarityClass`, params }),

  updateBuckMarketPlaceStatus: (buckId = '', data = {}) =>
    apiCall({
      method: 'PUT',
      url: `bucks/featureInMarketPlace/${buckId}`,
      data: data // { walletId, featureInMarketPlace }
    }),

  // Collection Details page
  getBuckByCollectionId: (collectionId, walletId) => {
    let params = {};
    if (walletId) params = { collectionId, walletId };
    else params = { collectionId };
    return apiCall({ url: `bucks/getBucksByCollectionId`, params: params });
  },

  getCollectionById: (collectionId) =>
    apiCall({ url: `collection/getCollectionById/${collectionId}` }),

  getAllBucksByCollection: (collectionId) =>
    apiCall({
      url: `collection/getAllBuckByCollection/${collectionId}`,
      params: { sort: 'title', sortBy: -1, page: 1, limit: 100 }
    }),

  setCollectionSettings: (walletId, collectionId = '', data = {}) =>
    apiCall({
      method: 'POST',
      url: `collection/setting/${walletId}/${collectionId}`,
      data: data
    }),
  // updateCollectionActiveStatus: (walletId, collectionStatusObj) =>
  //   apiCall({
  //     method: 'PUT',
  //     url: `collection/updateCollectionActiveStatus/${walletId}`,
  //     data: collectionStatusObj
  //   }),

  // updateCollectionMarketPlaceStatus: (collectionId = '', data = {}) =>
  //   apiCall({
  //     method: 'PUT',
  //     url: `collection/featureInMarketPlace/${collectionId}`,
  //     data: data
  //   }),

  // Bundle Details page
  getBundleById: (bundleId, isBucks, account) => {
    const query = account
      ? `rarity=${isBucks}&walletId=${account}`
      : `rarity=${isBucks}`;
    return apiCall({ url: `bundle/getBundleById/${bundleId}?${query}` });
  },

  getBundleDetails: (bundleId, walletId) =>
    apiCall({
      url: `bundle/getBundleById/${bundleId}`,
      params: { walletId }
    }),

  setBundleSettings: (walletId, bundleId, data = {}) =>
    apiCall({
      method: 'POST',
      url: 'bundle/setting/' + walletId + '/' + bundleId,
      data
    }),

  // Training Centre
  getAllCourses: (category = '', params = {}) => {
    if (!category) return apiCall({ url: 'training/course/getAll', params });
    return apiCall({ url: 'training/course/getAll/' + category, params });
  },

  getCourseDetail: (courseId) =>
    apiCall({ url: `training/course/getCourse/${courseId}` }),

  completeChapter: (requestObj) =>
    apiCall({
      method: 'POST',
      url: `training/chapter/markComplete`,
      data: requestObj
    }),

  // Markethub main page
  getFeatureStarData: (params = {}) =>
    apiCall({ url: `marketPlace/getFeaturedStar`, params }),

  // sNft market
  getPopularSnfts: (data = {}) =>
    apiCall({
      method: 'POST',
      url: 'snft/filter',
      data: { ...data, sort: 'popular' }
    }),

  getBrowseSnfts: (data = {}) =>
    apiCall({ method: 'POST', url: 'snft/filter', data: data }),

  // Markethub Bucks Market
  getPopularBucks: (data = {}) =>
    apiCall({
      method: 'POST',
      url: 'bucks/getAll',
      data: { ...data, sort: 'popular' }
    }),

  getBrowseBucks: (data = {}) =>
    apiCall({ method: 'POST', url: 'bucks/getAll', data: data }),

  getPurchaseableBucks: (params = {}) =>
    apiCall({ url: 'bucks/available', params: params }),

  purchaseBucks: (data = {}) =>
    apiCall({ method: 'POST', url: 'order/purchaseBuck', data: data }),

  CreateOnrampSession: (data = {}) =>
    apiCall({ method: 'POST', url: '/payment/', data: data }),

  getPopularCollections: (data = {}) =>
    apiCall({
      method: 'POST',
      url: 'collection/getAll',
      data: { ...data, sort: 'popular' }
    }),

  getBrowseCollections: (data = {}) =>
    apiCall({ method: 'POST', url: 'collection/getAll', data: data }),

  getPopularBundles: (data = {}) =>
    apiCall({
      method: 'POST',
      url: 'bundle/getAll',
      data: { ...data, sort: 'popular' }
    }),

  getBrowseBundles: (data = {}) =>
    apiCall({ method: 'POST', url: 'bundle/getAll', data: data }),

  // MarketHub Access NFT market
  getPopularAccessNFTs: (params = {}, data = { sort: 'popular' }) =>
    apiCall({ method: 'POST', url: 'accessNft/market', params, data }),

  getBrowseAccessNFTs: (params = {}, data = {}) =>
    apiCall({ method: 'POST', url: 'accessNft/market', params, data }),

  getPopularAccessNFTCollections: (params = {}) =>
    apiCall({
      url: 'accessNftCollection/market',
      params: { ...params, sort: 'popular' }
    }),

  getBrowseAccessNFTCollections: (params = {}) =>
    apiCall({ url: 'accessNftCollection/market', params: params }),

  // MarketHub discover stars
  getAllStars: (params = {}, data = {}) =>
    apiCall({ method: 'POST', url: 'marketPlace/feature/star', params, data }),

  // Cart api calls
  addCart: (cartObj) =>
    apiCall({ method: 'POST', url: 'carts/addCart', data: cartObj }),

  clearCart: (cartObj) =>
    apiCall({ method: 'POST', url: 'carts/deleteCart', data: cartObj }),

  updateCart: (cartObj) =>
    apiCall({ method: 'PUT', url: 'carts/updateCart', data: cartObj }),

  // Agents and Managers
  agentConfirmationAnswer: (ansObj) =>
    apiCall({ method: 'POST', url: 'agent/confirm/referral', data: ansObj }),

  getAgentsInfoForCreator: (account) =>
    apiCall({ url: 'agent/info/' + account }),

  getAllStarsPerManager: (account) =>
    apiCall({ url: 'manager/getAllStar/' + account }),

  getAllStarsListPerManager: (type = 'buck', params = {}) =>
    apiCall({ url: 'manager/star/leaderboard/' + type, params }),

  getManagerOverviewData: () => apiCall({ url: 'manager/myEarning' }),

  getManagerSalesPerStar: (starWalletId = '', asset = 'snft', params = {}) =>
    apiCall({
      url: 'manager/leaderBoard/' + starWalletId + '/' + asset,
      params
    }),

  getManagerStatsPerStar: (starWalletId = '') =>
    apiCall({ url: 'manager/stats/' + starWalletId }),

  getStarInfo: (type, account) =>
    apiCall({ url: type + '/star/info/' + account }),

  getAgentOverviewData: () => apiCall({ url: 'agent/earning' }),

  getAllStarsListPerAgent: (type = 'buck', params = {}) =>
    apiCall({ url: 'agent/star/leaderboard/' + type, params }),

  getAgentStatsPerStar: (starWalletId = '') =>
    apiCall({ url: `agent/${starWalletId}/earningStats` }),

  getAgentSalesPerStar: (starWalletId = '', asset = 'snft', params = {}) =>
    apiCall({
      url: 'agent/leaderBoard/' + starWalletId + '/' + asset,
      params
    }),

  getAllStarsPerAgent: (account) =>
    apiCall({ url: 'agent/getAllStar/' + account }),

  addStar: (starData, walletId) =>
    apiCall({
      method: 'POST',
      url: `agent/addStar/${walletId}`,
      data: starData
    }),

  addManager: (managerData, walletId) =>
    apiCall({
      method: 'POST',
      url: `manager/addManager/${walletId}`,
      data: managerData
    }),

  getAllManager: (walletId) =>
    apiCall({
      url: 'manager/getAll/' + walletId
    }),

  updateManagerData: (managerData, walletId) =>
    apiCall({
      method: 'PUT',
      url: `manager/update/${walletId}`,
      data: managerData
    }),

  agentCreditRequest: (creditRequestData) =>
    apiCall({
      method: 'POST',
      url: 'agent/credit/request',
      data: creditRequestData
    }),

  // Others - ungrouped
  checkIfRequested: (account) =>
    apiCall({ url: 'user/creatorHub/canrequest/' + account }),

  getCreatorHubAccessStatus: () =>
    apiCall({ url: 'user/getCreatorHubAccessStatus' }),

  contactForm: (requestObj) =>
    apiCall({
      method: 'POST',
      url: `contactus/saveContactUsDetail`,
      data: requestObj
    }),

  updateEmail: (account, userEmailObj) =>
    apiCall({
      method: 'PUT',
      url: 'user/updateEmail/' + account,
      data: userEmailObj
    }),

  updateDisplayName: (displayNameObj) =>
    apiCall({
      method: 'PUT',
      url: 'user/updateDisplayName',
      data: displayNameObj
    }),

  deleteProfilePic: (walletId) =>
    apiCall({ method: 'DELETE', url: 'user/profilePic/' + walletId }),

  verifyEmail: (userObj) =>
    apiCall({ method: 'POST', url: 'user/verifyEmailAddress', data: userObj }),

  getUserNotificationPreference: (walletId) =>
    apiCall({ url: `notification/getNotifcationPreferences/${walletId}` }),

  updateNotificationPreference: (walletId, notificationObject) =>
    apiCall({
      method: 'POST',
      url: 'notification/updateNotificationPreference/' + walletId,
      data: notificationObject
    }),

  checkUniqueNickName: (nickName) =>
    apiCall({ url: 'user/checkUniqueNickName', params: { nickName } }),

  registerUser: (userObj) =>
    apiCall({
      method: 'POST',
      url: 'user/register',
      data: userObj
    }),

  getBuckFromBundle: (bundleId) =>
    apiCall({
      url: 'bucks/getBuckFromBundle',
      params: { bundleId }
    }),

  postCreatorInvite: (obj) =>
    apiCall({
      method: 'POST',
      url: 'user/creatorHub/invite',
      data: obj
    }),

  addSellBuck: (sellBuckObj) =>
    apiCall({
      method: 'POST',
      url: 'order/bucks/redeems',
      data: sellBuckObj
    }),

  userAdBucks: (optionObj) =>
    apiCall({
      url: 'order/userAdBucks',
      params: optionObj
    }),

  // purchase NFT bucks
  completePurchaseUsingCreditCard: (data = {}) =>
    apiCall({
      method: 'POST',
      url: END_POINT_URL + '/payment/api/generate-checkout-page',
      data: data
    }),

  confirmPurchaseUsingCreditCard: (data = {}) =>
    apiCall({
      method: 'POST',
      url: END_POINT_URL + '/payment/api/check-status',
      data: data
    }),

  addOrder: (orderObj) =>
    apiCall({
      method: 'POST',
      url: 'order/addOrder',
      data: orderObj
    }),

  saveTxnReceipt: (data = {}) =>
    apiCall({ method: 'PUT', url: 'order/saveTxnReceipt', data: data }),

  // transfer buck
  transferBuck: (data = {}) =>
    apiCall({
      method: 'POST',
      url: 'order/transfer/buck',
      data: data
    }),

  getUserBucks: (params = {}) =>
    apiCall({ url: 'order/getUserBucks', params: params }),

  getUserAdBucks: (params = {}) =>
    apiCall({ url: 'order/userAdBucks', params: params }),

  getUserAdBuckDropHistory: (params = {}) =>
    apiCall({ url: 'bucks/stakehub/adBuck/drop', params: params }),

  getMilesStones: (walletId) => apiCall({ url: 'user/milestone/' + walletId }),

  // Mission NFTs
  getMintedMissionNFTs: (walletId = '', params = {}) =>
    apiCall({
      url: `${MINT_API_URL}/minted/missionNft/${walletId}`,
      params: params
    }),
  getMintedMissionNFTsCount: (walletId = '', params = {}) =>
    apiCall({
      url: `${MINT_API_URL}/minted/${walletId}`,
      params: params
    }),

  getAllBucksByStarWallet: (walletId = '', params = {}) =>
    apiCall({ url: 'bucks/getAll/' + walletId, params }),

  // StarConnect
  saveStarConnect: (walletId = '', data = {}) =>
    apiCall({
      method: 'POST',
      url: `starConnect/create/${walletId}`,
      data: data,
      timeout: 1200000
    }),

  createStarConnectPageBuilder: (data = {}) =>
    apiCall({ method: 'POST', url: 'starConnect/pageBuilder', data }),

  updateStarConnectPageBuilder: (data = {}) =>
    apiCall({ method: 'PUT', url: 'starConnect/pageBuilder', data }),

  createStarConnectSuccessPage: (data = {}) =>
    apiCall({ method: 'POST', url: 'starConnect/successPage', data }),

  updateStarConnectSuccessPage: (data = {}) =>
    apiCall({ method: 'POST', url: 'starConnect/successPage', data }),

  saveStarConnectTokenGatedContent: (data = {}) =>
    apiCall({ method: 'POST', url: 'starconnect/saveToken', data }),

  getAllStarConnectEditions: (walletId = '', params = {}) =>
    apiCall({ url: `starConnect/getAll`, params: { ...params, walletId } }),

  getStarConnectCurrentEditionNo: (walletId = '') =>
    apiCall({ url: 'starconnect/getCurrentEditionNo', params: { walletId } }),

  getCreatedStarConnectDetailsById: (starConnectId = '', params = {}) =>
    apiCall({ url: `starConnect/creator/` + starConnectId, params }),

  getStarConnectDetailsById: (starConnectId = '', params = {}) =>
    apiCall({ url: `starConnect/` + starConnectId, params: params }),

  getStarConnectLeaderboardData: (starConnectId = '', params = {}) =>
    apiCall({ url: `starConnect/leaderBoard/` + starConnectId, params }),

  getStarConnectCurrentEdition: (walletId = '', referral = false) =>
    apiCall({
      url: `starConnect/${walletId}/${referral ? 'referral' : 'dashboard'}`
    }),

  getStarConnectContract: (params = {}) =>
    apiCall({ method: 'GET', url: `starConnect/`, params: params }),

  starconnectClaim: (data = {}) =>
    apiCall({
      method: 'POST',
      url: 'starConnect/claim',
      data: data,
      timeout: 1200000
    }),

  getMyStarConnects: (params = {}) =>
    apiCall({ url: 'starConnect/balance', params }),

  getMyfanStatus: (type = '', params = {}) =>
    apiCall({ url: `fan/status/${type}`, params }),

  getMyfanScore: (type = '', params = {}) =>
    apiCall({ url: `fan/all/score/${type}`, params }),

  getCreatorFanScore: (type = '', params = {}) =>
    apiCall({ url: `fan/creator/score/${type}`, params }),

  getStakeHubRewards: () => apiCall({ url: 'snft/stakehub/rewards' }),

  getStakeHubPromotionRewardTableData: (params = {}) =>
    apiCall({ url: 'promotion/stakehub/reward', params: params }),

  getStakeHubFanScoreHistory: (params = {}) =>
    apiCall({ url: 'fan/score/history', params: params }),

  roadToSuperFan: () => apiCall({ url: 'fan/roadToSuperFan' }),

  claimUserRewards: (flag = '') =>
    apiCall({ method: 'POST', url: 'user/claim', data: { flag: flag } }),

  getStarLabIntegrationData: () => apiCall({ url: 'user/starlab' }),

  postStarLabIntegrationData: (data = {}) =>
    apiCall({ method: 'POST', url: 'user/starlab', data: data }),

  deleteStarLabIntegrationData: (data = {}) =>
    apiCall({ method: 'DELETE', url: 'user/starlab', data: data }),

  sendStarLabSampleRequest: (data = {}) =>
    apiCall({ method: 'POST', url: 'user/send-starlab-sample-request', data })
};

// getUserBucksCount: (walletId) =>
//   apiCall({
//     url: 'order/getUserBucks',
//     params: { walletId, specificData: true }
//   }),

//   async getAllBucks() {
//     try {
//       const bucksObj = await this.instance.get('bucks/getAll');
//       return bucksObj.data;
//     } catch (e) {
//       return e.response.data;
//     }
//   }
